import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { useI18n } from 'services/i18n';
import media from 'styles/media';
import { Background, Col, Container, Hidden, Row } from 'styles/grid';
import NonStretchedImage from 'components/non-stretched-image.js';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Block from 'components/block';
import SwitcherBlock from 'components/switcher-block';
import Links from 'components/links';
import Cart from 'images/icons/cart.svg';
import Check from 'images/icons/check.svg';
import {
  PrimaryTitle,
  SecondaryTitle,
  BiggerText,
  BigText,
  ParagraphTitle,
  ParagraphText,
} from 'components/texts';
import { DemoLink } from 'components/buttons';

const MainIconRow = styled(Row)`
  margin-bottom: 40px;
  ${media.md`
    margin-bottom: 60px;
  `}
`;

const ListContainer = styled(Container)`
  padding: 30px 30px;
  ${media.md`
    padding: 60px 40px;
  `}
`;

const ListCol = styled(Col)`
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  svg {
    flex: 0 0 2em;
    margin-right: 2em;
    height: 2em;
    width: 2em;
  }
`;

const CrossSellPage = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      fr: allFile(filter: { relativeDirectory: { eq: "cross-sell/fr" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      en: allFile(filter: { relativeDirectory: { eq: "cross-sell/en" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const images = {
    fr: data.fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };
  return (
    <Layout>
      <SEO
        title={t('seo.title.cross-sell')}
        lang={language}
        description={t('seo.description.cross-sell')}
      />
      <section>
        <Container>
          <MainIconRow justify="center">
            <Col md="content" style={{ textAlign: 'center' }}>
              <Cart height="4rem" width="4rem" />
            </Col>
          </MainIconRow>
          <Row justify="center">
            <Col md="content">
              <PrimaryTitle align="center">{t('cross-sell.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Hidden md lg xl>
            <Row justify="center">
              <Col style={{ marginTop: 40 }} sm="content">
                <DemoLink id="ga-demo-plugin">{t('index.appointment')}</DemoLink>
              </Col>
            </Row>
          </Hidden>
        </Container>
      </section>
      <Background color="rgba(10, 64, 67, 1)">
        <section>
          <Row justify="center" style={{ paddingTop: '24px' }}>
            <Col sm={12} md={8}>
              <NonStretchedImage fluid={images[language].crosssell_1.fluid} alt={''} />
            </Col>
          </Row>
        </section>
      </Background>

      <Background>
        <section>
          <ListContainer>
            <Row justify="center">
              <Col sm={12} md={10} lg={9}>
                <BiggerText style={{ marginBottom: '2.5rem' }} desktopAlign="center">
                  {t('cross-sell.outcome1')}
                </BiggerText>
              </Col>
            </Row>
            <Row justify="space-around">
              <ListCol sm={12} md={4}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('cross-sell.outcome2')}
                </BigText>
              </ListCol>
              <ListCol sm={12} md={4}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('cross-sell.outcome3')}
                </BigText>
              </ListCol>
              <ListCol sm={12} md={4}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('cross-sell.outcome4')}
                </BigText>
              </ListCol>
            </Row>
          </ListContainer>
        </section>
      </Background>

      <section>
        <Container>
          <Row justify="center">
            <Col>
              <SecondaryTitle>{t('cross-sell.title2')}</SecondaryTitle>
            </Col>
          </Row>
          <Block
            titleText={t('cross-sell.text1')}
            imageFluid={images[language].crosssell_2.fluid}
            alt={t('seo.alt.index.pluginMorphology')}
          >
            <ParagraphTitle weight="bold">{t('cross-sell.text2')}</ParagraphTitle>
            <ParagraphText>{t('cross-sell.text3')}</ParagraphText>
          </Block>
        </Container>
      </section>
      <section>
        <Container>
          <SwitcherBlock
            mainTitle={t('cross-sell.text4')}
            titleOne={t('cross-sell.text5')}
            textOne={t('cross-sell.text6')}
            titleTwo={t('cross-sell.text7')}
            textTwo={t('cross-sell.text8')}
            imageFluidOne={images[language].crosssell_3.fluid}
            imageFluidTwo={images[language].crosssell_3bis.fluid}
            altOne={t('cross-sell.text5')}
            altTwo={t('cross-sell.text7')}
            isImageLeft={true}
          />
        </Container>
      </section>
      <Links withBlockTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default CrossSellPage;
